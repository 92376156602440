import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 300px;
  height: 150px;
  border: 2px solid #e6e6e6;
  border-radius: 10px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;

  :nth-child(1) {
    z-index: 9;
    margin-right: 10px;
    border: 2px solid #ff9caf;

    :hover {
      border-color: #ff5474;
    }

    @media (max-width: 1255px) {
      margin: 0;
    }
  }
  :nth-child(2) {
    z-index: 9;
    margin-left: 10px;
    border: 2px solid #ff9caf;

    :hover {
      border-color: #ff5474;
    }

    @media (max-width: 1255px) {
      margin: -25px 0 0 0;
    }
  }
  :nth-child(3) {
    margin-top: -15px;
    z-index: 10;
    border: 2px solid #ff9caf;

    :hover {
      border-color: #ff5474;
    }

    @media (max-width: 1255px) {
      margin: -25px 0 0 0;
    }
  }
  :nth-child(4) {
    width: 500px;
    height: auto;
    margin-top: 20px;
    padding: 30px 0;
    border: 2px solid #49c68d;

    :hover {
      border-color: #22de00;
    }

    svg {
      fill: #faa62d;
    }

    @media (max-width: 1024px) {
      padding: 10px 20px;
    }
  }
`

const LogoSection = styled.div`
  width: 80px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LogoDiv = styled.div`
  svg {
    width: 80px;
    height: auto;
  }
`

const TextSection = styled.div`
  text-align: center;
  margin: 0 5px;
  h2 {
    font-size: 1.1em;
    padding: 0 0 15px;
  }
  p {
    font-size: 0.9em;
    margin: 0;
  }
`

export default function JobsCard(props) {
  return (
    <Wrapper>
      <LogoSection>
        <LogoDiv>
          <props.svg />
        </LogoDiv>
      </LogoSection>
      <TextSection>
        <h2>{props.role}</h2>
        <p>{props.dayRate}</p>
        <p>{props.perm}</p>
      </TextSection>
    </Wrapper>
  )
}
