import React from "react"
import styled from "styled-components"
import WebDevIcon from "../../images/assets/web-development.svg"
import AgileWorking from "../../images/assets/agile-working.svg"
import SupportIcon from "../../images/assets/support-maintenance.svg"
import UxUiIcon from "../../images/assets/ux-ui-design.svg"

const ContentContainer = styled.div`
  margin: 62px auto;
  display: flex;
  flex-flow: column wrap;
  max-width: 1280px;
`
const ServicesWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 5%;

  @media (max-width: 1023px) {
    flex-wrap: wrap;
  }
`

const ServicesCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column wrap;
  width: 25%;
  padding: 0 7px;

  svg {
    width: 100px;
    height: auto;
    margin: 10px 0;
    padding-top: 10px;
    transition: padding 0.5s ease-out;
  }
  svg:hover {
    padding-top: 0px;
    padding-bottom: 10px;
  }

  h2 {
    font-size: 1.8em;
    text-align: center;
  }

  p {
    text-align: left;
    padding: 0 10px;
  }

  @media (max-width: 1023px) {
    width: 50%;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0px;

    h2 {
      font-size: 1.4em;
    }

    p {
      text-align: center;
    }
  }
`

export default function ContentSection1() {
  return (
    <ContentContainer>
      <ServicesWrapper>
        <ServicesCard>
          <WebDevIcon />
          <h2>
            Web <br />
            Development
          </h2>
          <p>
            From company websites to bespoke web applications, our trained Level
            4 Software Engineers use a range of frameworks to build and assist
            in all web development projects. Our focus is to effectively
            maximise your ROI.
          </p>
        </ServicesCard>
        <ServicesCard>
          <AgileWorking />
          <h2>
            Agile <br />
            Working
          </h2>
          <p>
            We've built an effective team of developers that follow agile
            methodologies when working in-house or out with clients. This
            ensures that all projects are planned and delivered with a flexible
            approach.
          </p>
        </ServicesCard>
        <ServicesCard>
          <SupportIcon />
          <h2>
            Support &amp; <br />
            Maintenance
          </h2>
          <p>
            When a project has been delivered in budget and on time, we offer
            on-going support such as scheduled updates, staggered / personalised
            feature creation or future application rework.
          </p>
        </ServicesCard>
        <ServicesCard>
          <UxUiIcon />
          <h2>
            UX &amp; UI <br />
            Design
          </h2>
          <p>
            We keep usablility, accessibility and modern visual design in mind
            throughout our develpoment process, aiming to optimise the users
            interaction with the end product as best as possible.
          </p>
        </ServicesCard>
      </ServicesWrapper>
    </ContentContainer>
  )
}
