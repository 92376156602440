import React from "react"
import Layout from "../components/HeaderFooter/Layout"
import ContentHeader from "../components/WhatWeDo/ContentHeader"
import ContentSection1 from "../components/WhatWeDo/ContentSection1"
import ContentSection2 from "../components/WhatWeDo/ContentSection2"
import ContentSection3 from "../components/WhatWeDo/ContentSection3"
import { Helmet } from "react-helmet"

export default function WhatWeDo() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ripplz - What We Do</title>
        <meta
          name="description"
          content="Hiring the right developer in todays market is essential for your product development, that's why we provide cost effective access to the latest digital skills"
        />
      </Helmet>
      <div>
        <ContentHeader />
        <ContentSection1 />
        <ContentSection2 />
        <ContentSection3 />
      </div>
    </Layout>
  )
}
