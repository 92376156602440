import React from "react"
import styled from "styled-components"
import JobsCard from "./JobsCard"
import Reed from "../../images/assets/reed-logo-svg.svg"
import CwJobs from "../../images/assets/cwjobs-logo-svg.svg"
import JobSite from "../../images/assets/jobsite-logo-svg.svg"
import Ripplz from "../../images/assets/logo.svg"

const ContentContainer = styled.div`
  align-items: center;
  flex-flow: column wrap;
  margin: 2% 0;

  @media (min-width: 600px) {
    display: flex;
  }
`
const QuestionWrapper = styled.div`
  margin-bottom: 10px;

  p {
    margin: 0;

    @media (max-width: 600px) {
      padding-left: 15px;
    }
  }

  h1 {
    font-size: 1.6em;

    @media (max-width: 600px) {
      text-align: center;
    }
  }
`
const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1280px;

  @media (max-width: 1023px) {
    flex-flow: column wrap;
    align-items: center;
    margin: 2% 5%;
  }
`
const TextBox = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    width: 70%;
  }

  @media (max-width: 1023px) {
    width: 100%;

    p {
      width: 100%;
    }
  }
`
const CardContainer = styled.div`
  width: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;
  min-width: 350px;

  @media (max-width: 1255px) {
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    flex-direction: row;
  }

  svg {
    width: 100%;

    @media (max-width: 1023px) {
      height: 100%;
    }
  }
`

export default function ContentSection2() {
  return (
    <ContentContainer>
      <QuestionWrapper>
        <p>Have you ever asked...</p>
        <h1>Why is it so difficult to find developer talent?</h1>
      </QuestionWrapper>
      <ContentWrapper>
        <TextBox>
          <p>
            ‘Software Developer’ is the most in demand role in the tech market
            and makes up 6% of all advertised digital roles. However, the talent
            pool is shrinking due to a 40% reduction in students choosing to
            take IT subjects at GCSE level and further reductions at A-Level and
            University, thus increasing the cost of current experienced
            professionals.
            <br />
            <br />
            We are bucking the trend by re-skilling ambitious professionals into
            the tech world and contributing to the planned 40% growth of the UK
            tech workforce across the next two years!
          </p>
        </TextBox>
        <CardContainer>
          <JobsCard
            svg={Reed}
            role="Front-End Developer"
            dayRate="£400-£700 per day contract"
            perm="£40,000 - £60,000 perm"
          />
          <JobsCard
            svg={CwJobs}
            role="Back-End Developer"
            dayRate="£450-£800 per day contract"
            perm="£50,000 - £80,000 perm"
          />
          <JobsCard
            svg={JobSite}
            role="Full Stack Developer"
            dayRate="£600-£1000 per day contract"
            perm="£60,000 - £80,000 perm"
          />
          <JobsCard
            svg={Ripplz}
            role="Junior Developer - Front / Backend"
            dayRate="£250 per day exVAT + Retained Options"
            perm="Lvl 4 Software Development Apprenticeship Qualified"
          />
        </CardContainer>
      </ContentWrapper>
    </ContentContainer>
  )
}
